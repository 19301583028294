import { IonList, IonItem, IonLabel, IonInput, IonToggle, IonRadio, IonCheckbox, IonItemSliding, IonItemOption, IonItemOptions, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { AddDocument } from '../components/AddDocument';
import './Tab2.css';

const Tab2: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Documents</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Documents</IonTitle>
          </IonToolbar>
        </IonHeader>
          <IonList>
            <IonItem>
              <IonLabel>Pokémon Yellow</IonLabel>
              <IonLabel color="secondary">Secondary Label</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Mega Man X</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>The Legend of Zelda</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Pac-Man</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Super Mario World</IonLabel>
            </IonItem>
        </IonList>
        <AddDocument />
      </IonContent>
    </IonPage>
  );
};

export default Tab2;
