/*
import {
  getAuth,
  //sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDvqChMDrxWN93fBqVXPZy99oGg5rtjYTk",
  authDomain: "techpods-app.firebaseapp.com",
  databaseURL:
    "https://techpods-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "techpods-app",
  storageBucket: "techpods-app.appspot.com",
  messagingSenderId: "975428943549",
  appId: "1:975428943549:web:6e74ebe3f2326bcdd201d4",
  measurementId: "G-XD8P12JBMT",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: "https://app.techpods.co/finishSignUp",
  // This must be true.
  handleCodeInApp: true,
  dynamicLinkDomain: "app.techpods.co",
};

export const checkUrlForEuth = () => {
  const auth = getAuth();
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email: string = window.localStorage.getItem("emailForSignIn") || "";
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt("Please provide your email for confirmation") || "";
    }
    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem("emailForSignIn");
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
  }
};

export const emailSignIn = async (email: string) => {
  const auth = getAuth();
  try {
    sendSignInLinkToEmail(auth, email, actionCodeSettings);
    window.localStorage.setItem("emailForSignIn", email);
  } catch (error) {
    throw new Error((error as Error).message);
  }
};


*/





import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import {
  getAuth,
  //sendSignInLinkToEmail,
  //isSignInWithEmailLink,
  //signInWithEmailLink,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDvqChMDrxWN93fBqVXPZy99oGg5rtjYTk",
  authDomain: "techpods-app.firebaseapp.com",
  databaseURL:
    "https://techpods-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "techpods-app",
  storageBucket: "techpods-app.appspot.com",
  messagingSenderId: "975428943549",
  appId: "1:975428943549:web:6e74ebe3f2326bcdd201d4",
  measurementId: "G-XD8P12JBMT",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
//const analytics = getAnalytics(app)


export const googleSignIn = async () => {
  const auth = getAuth();
  try {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
    //window.localStorage.setItem("emailForSignIn", email);
  } catch (error) {
    throw new Error((error as Error).message);
  }
}

export const zohoSignIn = async () => {
  
}
