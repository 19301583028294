/* Using with IonActionSheet Component */

import React, { useState } from 'react';
import { IonActionSheet, IonContent, IonButton } from '@ionic/react';
import { bandageOutline, airplaneOutline, skullOutline, heart, close } from 'ionicons/icons';

export const AddDocument: React.FC = () => {
  const [showActionSheet, setShowActionSheet] = useState(false);

  return (
    <IonContent>
      <IonButton onClick={() => setShowActionSheet(true)} expand="block">
        Създай нов документ
      </IonButton>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass='my-custom-class'
        buttons={[{
          text: 'Болничен',
          icon: bandageOutline,
          handler: () => {
            console.log('sick-leave');
          }
        }, {
          text: 'Платен отпуск',
          icon: airplaneOutline,
          handler: () => {
            console.log('paid');
          }
        }, {
          text: 'Неплатен отпуск',
          icon: skullOutline,
          handler: () => {
            console.log('non-paid');
          }
        }, {
          text: 'Cancel',
          icon: close,
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }]}
      >
      </IonActionSheet>
    </IonContent>
  );
}