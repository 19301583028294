import { Redirect, Route } from 'react-router-dom';
import Protected from './components/Protected';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { person, document, helpBuoy } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import SignIn from './pages/SignIn';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/signin">
          <SignIn />
        </Route>
        <Route exact path="/profile">
          <Protected><Tab1 /></Protected>
        </Route>
        <Route exact path="/documents">
          <Protected><Tab2 /></Protected>
        </Route>
        <Route path="/faq">
          <Protected><Tab3 /></Protected>
        </Route>
        <Route exact path="/">
          <Redirect to="/documents" />
        </Route>
      </IonRouterOutlet>
      <Protected>
        <IonTabs>
          <IonTabBar slot="bottom">
            <IonTabButton tab="profile" href="/profile">
              <IonIcon icon={person} />
              <IonLabel>Профил</IonLabel>
            </IonTabButton>
            <IonTabButton tab="documents" href="/documents">
              <IonIcon icon={document} />
              <IonLabel>Документи</IonLabel>
            </IonTabButton>
            <IonTabButton tab="faq" href="/faq">
              <IonIcon icon={helpBuoy} />
              <IonLabel>FAQ</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </Protected>
    </IonReactRouter>
  </IonApp>
);

export default App;
