import { Redirect } from "react-router-dom";
import { getAuth } from "firebase/auth";
import SignIn from "../pages/SignIn";

const Protected = ({ children }: { children: any }) => {
    const auth = getAuth();

    if (!auth.currentUser) {
        return <><Redirect to="/signin" /><SignIn /></>;;
    }
    return children;
};
export default Protected;